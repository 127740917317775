import { useSplit } from './useSplit'

export function useSplitEnabled<T = Record<string, never>>(
  name: string,
  attributes = {},
  enabledTreatment = 'on',
) {
  const { split, isReady, isTimedout, config } = useSplit<T>(name, attributes)
  const isEnabled =
    split.treatment === enabledTreatment && isReady && !isTimedout
  const ready = isReady && !isTimedout
  return { isEnabled, isReady, isTimedout, config: ready ? config : {} }
}
